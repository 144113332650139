.wrapper {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  div:nth-child(1) img {
    width: 75vw;
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      text-align: center;
      margin-bottom: 0.4em;
      font-size: 1.5rem;
      margin-left: 1em;
      margin-right: 1em;
      margin-top: 1em;
    }

    p {
      margin-left: 1.4em;
      margin-right: 1.4em;
      color: hsl(229, 8%, 60%);
    }

    .main-button-wrapper {
      margin-top: 1em;
      display: flex;
      flex-direction: column;

      button:nth-child(2) {
        margin-top: 1em;
      }
    }
  }
}

@media (min-width: 40em) {
  .content {
    flex-direction: row-reverse;
    justify-content: space-around;

    div:nth-child(1) img {
      width: 40vw;
    }

    div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 2em;
      width: 35%;
      text-align: left;

      h1 {
        text-align: left;
        font-size: 2.3rem;
        margin-bottom: 0.4em;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
      }
      p {
        margin-left: 0;
        margin-right: 0;
      }

      .main-button-wrapper {
        margin-top: 1.7em;
        display: flex;
        flex-direction: row;

        button:nth-child(2) {
          margin-left: 1em;
          margin-top: 0;
        }
      }
    }
  }
}

//Tablet Shape

.tablet {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 2.5em;
  width: 60vw;
  height: 45vw;
  background: hsl(231, 69%, 60%);
  margin-left: 10em;
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
}

@media (min-width: 40em) {
  .tablet {
    position: absolute;
    z-index: -1;
    right: 0;
    top: 10em;
    width: 40vw;
    height: 18vw;
    background: hsl(231, 69%, 60%);
    margin-left: 10em;
    border-top-left-radius: 300px;
    border-bottom-left-radius: 300px;
  }
}
