.card-wrapper {
  width: 100%;
  margin-top: 4em;
}
.card-position {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2em;
  width: 80%;
  max-width: 20em;
}

@media (min-width: 45em) {
  .card-content {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2em;
    margin: 0 1.5em;
    width: 100%;
    max-width: 52em;

    div:nth-child(2) {
      margin-top: 3em;
    }

    div:nth-child(3) {
        margin-top: 5em;
      }
  }
}
