/* Dropdown Button */
.desktop-dropbtn {
  background: none;
  border: 3px solid white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.4em;
  color: white;
  transition: all 0.4s ease-in-out;

  /* Dropdown button on hover & focus */
  &:hover,
  &:focus {
    background-color: white;
    color: black;
    outline: none !important;
  }
}

/* The container <div> - needed to position the dropdown content */
.desktop-dropdown {
  position: relative;
  display: flex;
}

/* Dropdown Content (Hidden by Default) */
.desktop-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0em;

  /* Links inside the dropdown */
  a {
    color: #000;
    text-decoration: none;
    display: block;
    padding: 1em;
    font-weight: bold;
  }

  /* Change color of dropdown links on hover */
  a:hover {
    background-color: #555;
    color: white;
    padding-bottom: 1em;
    border-bottom: none;
  }
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

/* Define menu positions */
.desktop-dropdown-content.menu-one {
  right: 5.5em; //Adjust position when you added extra dropdown
  padding: 0;
}

.desktop-dropdown-content.menu-two {
  right: 0.5em; //Adjust position when you added extra dropdown
  padding: 0;
}

/* If you add another dropdown, just copy above and change the number in order */
// .desktop-dropdown-content.menu-three {
//   right: 0em;
//   padding: 0;
// }
