.feature-wrapper {
  width: 100%;
  margin-top: 4em;
}

.feature-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.feature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 1.3em;

  p {
    margin-top: 1em;
    line-height: 1.5em;
    color: hsl(229, 8%, 60%);
  }
}

@media (min-width: 40em) {
  .feature-content {
    width: 25em;
    margin: 0;
  }
}
