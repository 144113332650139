.download-wrapper {
  width: 100%;
}

.download-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.download-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 1.4em;

  p {
    margin-top: 1.1em;
    line-height: 1.5em;
    color: hsl(229, 8%, 60%);
  }
}

@media (min-width: 40em) {
  .download-content {
    width: 24em;
  }

  .download-wrapper {
    margin-top: 4em;
  }
}
