.footertwo-wrapper {
  width: 100%;
}

.footertwo-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: hsl(229, 31%, 21%);
  padding: 1em;

  .nav-links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.8em;

    .nav-links a {
      color: #fff;
      text-decoration: none;
      line-height: 3em;
      font-weight: bold;
      text-align: center;
      transition: color 0.4s ease-in-out;

      &:hover {
        color: hsl(0, 94%, 66%);
      }
    }
  }

  .social-links {
    display: flex;
    margin-top: 0.4em;
    font-size: 1.7rem;
    margin-bottom: 0.9em;

    div:nth-child(1) {
      margin-right: 0.7em;
    }

    div a {
      color: #fff;
      transition: color 0.4s ease-in-out;
    }

    div a:hover {
      color: hsl(0, 94%, 66%);
    }
  }
}

@media (min-width: 40em) {
  .footertwo-content {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .nav-links-wrapper {
      flex-direction: row;
      margin-top: 0;
      align-items: center;

      div:nth-child(1) img {
        margin-right: 1.5em;
        margin-top: 0.4em;
      }

      .nav-links {
        display: flex;

        a {
          margin-right: 1.5em;
        }
      }
    }

    .social-links {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
